// @flow strict
import React from 'react';
import styles from './Copyright.module.scss';

const Copyright = () => (
  <div className={styles["copyright"]}>
    Built with <span className={styles["copyright__heart"]}>♥</span> by Nicolas Nunge<br />
  </div>
);

export default Copyright;
